
.judge-management {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .judge-header {
    display: flex;
    justify-content: flex-end;
  }
  .logo-uploader {
    ::v-deep.el-upload {
      width: 80px;
      height: 80px;
      background: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-uploader-icon {
        font-size: 32px;
        color: #a0a0a0;
      }
    }
  }
  .column-logo-box {
    .column-logo {
      width: 48px;
      height: 48px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      box-sizing: content-box;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .logo-src-box {
    display: flex;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    i {
      line-height: 1;
      font-size: 14px;
      color: #dcdcdc;
      position: absolute;
      right: -20px;
      top: 0;
      cursor: pointer;
    }
  }
  .school-prompt {
    color: #999;
    font-size: 12px;
    width: 200px;
    height: 200px;
  }
}
